import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactBotMap from "../components/contact-bot-map"

const ContactBotPage = () => (
  <Layout>
    <SEO
      title="Kontaktdaten"
      description="Finden Sie die Kontaktdaten für den BürgerBot Ihrer Stadt und nehmen Sie mit dem Smartphone Kontakt auf."
    />
    <ContactBotMap />
  </Layout>
)

export default ContactBotPage
